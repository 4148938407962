<template>
  <v-card class="pa-4" flat>
    <v-row no-gutters align="center" class="mb-5">
      <v-btn
        depressed
        @click="
          $router.push({
            name: 'Extracurricular'
          })
        "
      >
        <v-icon>mdi-arrow-left</v-icon>
        {{ $t("app.back") }}
      </v-btn>
      <v-spacer />
      <v-text-field
        v-model="search"
        :label="$t('app.search')"
        :class="$vuetify.breakpoint.xsOnly && 'mt-2'"
        class="d-inline-block mr-2"
        outlined
        dense
        hide-details
        clearable
        style="max-width: 250px"
        prepend-inner-icon="mdi-magnify"
      />
      <v-btn
        class="gradient-primary"
        dark
        depressed
        @click="dialogInput = true"
      >
        {{ $t("extracuricular.input_member") }}
        <v-icon class="ml-2">mdi-plus-circle-outline</v-icon>
      </v-btn>
    </v-row>
    <v-data-table
      :headers="headerMasterExtra"
      :items="dataTable.data"
      :loading="table.loading"
      fixed-header
      hide-default-footer
      disable-pagination
      class="elevation-0"
    >
      <template #item.action="{ item }">
        <v-tooltip left>
          <template #activator="{ on }">
            <v-btn
              v-on="on"
              :disabled="item.is_member == 0"
              small
              icon
              dark
              class="error"
              @click="showDialogDicline(item)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("extracuricular.delete_member") }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <Pagination
      :length="dataTable.last_page"
      :total="dataTable.total"
      :current-page="table.page"
      :limit="table.limit"
      :handler="paginateChange"
      class="ma-3"
    />
    <DialogInputMember
      :dialog="dialogInput"
      @close="dialogInput = false"
      @refresh="getMember"
    />
    <ModalConfirm
      :content="$t('extracuricular.dialog.confirm_decline')"
      :close="() => (this.confirmDecline = false)"
      :isOpen="confirmDecline"
      :save="decline"
      :loading="loadingConfirm"
    />
  </v-card>
</template>

<script>
import {
  listMember,
  declineMember
} from "@/api/admin/academic/extracurricular";

let typingTimer;
const doneTypingInterval = 750;

export default {
  created() {
    this.getMember();
  },
  components: {
    Pagination: () => import("@/components/Pagination"),
    DialogInputMember: () => import("./components/DialogInputMember"),
    ModalConfirm: () => import("@/components/ModalConfirm")
  },
  watch: {
    search() {
      this.searchHandler();
    }
  },
  data() {
    return {
      search: "",
      confirmDecline: false,
      loadingConfirm: false,
      dialogInput: false,
      itemStudent: {},
      table: {
        loading: false,
        page: 1,
        limit: 10,
        search: ""
      },
      loadingTable: false,
      dataTable: { data: [] },
      headerMasterExtra: [
        {
          text: this.$i18n.t("app.name"),
          align: "left",
          value: "name"
        },
        {
          text: "NIS",
          value: "nis"
        },
        {
          text: this.$i18n.t("app.class"),
          value: "kelas"
        },
        {
          text: this.$i18n.t("master_data.student.action"),
          sortable: false,
          value: "action",
          width: 100
        }
      ]
    };
  },
  methods: {
    showDialogDicline(item) {
      this.confirmDecline = true;
      this.itemStudent = item;
    },
    decline() {
      this.loadingConfirm = true;
      const body = {
        student: this.itemStudent.id,
        extra: Number(this.$route.params.id)
      };
      declineMember(body)
        .then(response => {
          if (response.data.code) {
            this.snackBar(
              true,
              this.$i18n.t("extracuricular.msg.reject_member")
            );
          } else {
            this.snackBar(false, response.data.message);
          }
          this.getMember();
          this.loadingConfirm = false;
          this.confirmDecline = false;
        })
        .catch(error => {
          this.snackBar(false, error);
          this.confirmDecline = false;
          this.loadingConfirm = false;
        });
    },
    searchHandler() {
      clearTimeout(typingTimer);
      typingTimer = setTimeout(() => {
        this.table.page = 1;
        this.getMember();
      }, doneTypingInterval);
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    async getMember() {
      const idExtra = this.$route.params.id;
      const filter = {
        page: this.table.page,
        limit: this.table.limit,
        search: this.search
      };
      if (idExtra) {
        this.table.loading = true;
        const response = await listMember({ id: idExtra, ...filter });
        if (response.data.code) {
          this.dataTable = response.data.data;
        } else {
          this.snackBar(false, response.data.message);
        }
        this.table.loading = false;
      }
    },
    paginateChange(page, limit) {
      this.table.page = page;
      this.table.limit = limit;
      this.getMember();
    }
  }
};
</script>
